import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/directorofadmin/1.jpeg';
// import blogd2 from '../../assets/images/2024/4th-annual-conference/1.jpeg';
// import blogd3 from '../../assets/images/2024/senate-committee-commends-mgt/3.jpeg';
// import blogd4 from '../../assets/images/2024/senate-committee-commends-mgt/4.jpeg';
// import blogd5 from '../../assets/images/2024/senate-committee-commends-mgt/5.jpeg';
// import blogd6 from '../../assets/images/2024/senate-committee-commends-mgt/6.jpeg';
// import blogd7 from '../../assets/images/2024/senate-committee-commends-mgt/7.jpeg';
// import blogd8 from '../../assets/images/2024/senate-committee-commends-mgt/8.jpeg';
// import blogd9 from '../../assets/images/2024/senate-committee-commends-mgt/9.jpeg';
// import blogd10 from '../../assets/images/2024/senate-committee-commends-mgt/10.jpeg';
// import blogd11 from '../../assets/images/2024/senate-committee-commends-mgt/11.jpeg';
// import blogd12 from '../../assets/images/2024/senate-committee-commends-mgt/12.jpeg';




import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                FEDERAL MINISTRY OF HEALTH APPOINTS MS. OMOLOLA OLUBUKUNOLA FAKEYE 
                (JP) AS THE SUBSTANTIVE DIRECTOR OF ADMINISTRATION IN 
                LAGOS UNIVERSITY TEACHING HOSPITAL
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd1} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                Ms. <strong>Omolola Olubukunola Fakeye (JP)</strong>was appointed effectively on May 31st, 2024 as the substantive
                 <strong>Director of Administration (LUTH).</strong>at <strong>Lagos University Teaching Hospital (LUTH).</strong>
                 becoming the first female to hold this position in the hospital’s 62- year history. {' '}
                  
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    <img src={blogd3} height={370}/>
                    <p>
                    With a focus on interventional cardiology, the conference aims to 
                    explore groundbreaking techniques, technologies, and strategies 
                    aimed at improving patient outcomes and revolutionizing cardiovascular
                    care.
                      <br />
                      <br />
                      The 4th LUTH Scientific Annual Conference serves as a platform for 
                      healthcare professionals, researchers, and industry experts to come 
                      together, exchange knowledge, and collaborate towards addressing this
                      critical healthcare challenge.
                    </p>
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                Ms. Fakeye was born on the 19th of February, 1971 in Lagos State where she also had her Primary, secondary  and Advance Level (AL) education. She also graduated from the University of Ibadan with both B.sc and MSc in Political Science, specializing in Public Administration in 1995 and 1998 respectively. She further obtained her MBA(finance) from Lagos State University ( LASU) in 2005. 

                  <br />
                  {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                  <br />
                  She is a seasoned Human Resources Practitioner, holding a license to practice and has over 25years of experience in Lagos University Teaching Hospital. 
                Ms. Fakeye professional journey includes significant roles such as; 
                Accreditation desk officer, Secretary, Board of studies for Luth Schools, 
                Secretary, LUTH Schools, Academic Board, 
                Unit Head, Junior Administration, Personnel Division, LUTH, 
                Head, Human Resources Division, LUTH, 
                Principal Desk Officer, Servicom LUTH, 
                Head, Corporate Services/ PRO, LUTH. 
                She Lectures Management at School of Health Information Management for 18years, Examiner, Chartered Institute of Personnel Management for over 13years, 
                Writer, CIPMN, Manpower planning student study pack
                Charter President, Inner Wheel Club of LUTH
                Past District Treasurer, District 911, Nigeria 2017/2018
                Vice President, Inner Wheel Club LUTH 2023/2024

                In December 2023, she was appointed as the Acting Director of Administration.
                  <br />
                  {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                  <br />
                  In this role, Ms. Omolola O. Fakeye oversees and manages the administrative functions, leveraging her extensive experience to drive excellence and growth. Ms. Fakeye’s leadership is characterized by a deep commitment to efficiency, innovation and the highest standards of Healthcare administration. She aims to enhance operational effectiveness, foster a supportive and professional environment for staff and ensure the delivery of superior healthcare services. Her visionary approach and strategic initiatives are set to propel LUTH towards achieving greater milestones in Healthcare Administration and Patient Care, reinforcing the Hospital’s reputation as a leading institution.
                </p>
                
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                LUTH invites healthcare professionals, researchers, academics, students, and 
                industry stakeholders to participate in this exciting and intellectually 
                stimulating event. Together, let us harness the power of innovation, 
                collaboration, and ethical inquiry to advance the frontiers of cardiovascular 
                care and improve health outcomes for all.
                </span> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p> */}
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div> */}
                
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} width={500} />
                  </div>
                </div> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Administrative Department</a>
                </p>
              </div>
                <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
